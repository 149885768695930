import React from 'react';
import CheckoutInfoLoading from './CheckoutInfoLoading';

const MobileLoading = () => {
	return (
		<div
			className='w-100 h-100'
			style={{
				padding: '2rem 0 0 0',
				maxWidth: '700px',
				width: '100%',
				position: 'relative',
				margin: '0 auto',
				justifyContent: 'center',
			}}>
			<CheckoutInfoLoading />
		</div>
	);
};

export default MobileLoading;
