// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */

// FUNCTIONS TO SEND ANALYTICS EVENTS

import { EKHO_APPS_ENUM, SUPPORT_TOPICS } from '@ekhodealer/ekho-common';
import { fetchDeviceInfo } from '@ekhodealer/ekho-common/utils';
import { createLog, sendTransactionalEmail } from '../serverCalls';

export function trackingEnabled() {
	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get('preview') !== 'true';
}

export function track_buyer_clicked_log_in_within_checkout(oemProfileProps) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('buyer_clicked_log_in_within_checkout', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
	});
}

export function group_user(oemProfileProps) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.group(`${oemProfileProps._oemKey}`, {
		oemId: oemProfileProps.id,
		oemWebsite: oemProfileProps.websiteUrl,
		oemKey: oemProfileProps._oemKey,
		oemBrandColor: oemProfileProps.stylingMap.brandColor,
		oemBrandColorLight: oemProfileProps.stylingMap.brandColorLight,
		oemLogoUrl: oemProfileProps.stylingMap.darkFull,
		oemName: oemProfileProps.name,
		sourceData,
	});
}

export function track_checkout_financing_modal_opened(
	oemProfileProps,
	vehicles,
	activeVehicleIndex,
	totalCartCost,
	isPreorderSession,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_financing_modal_opened', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		vehicleBasePrice: vehicles[activeVehicleIndex].basePrice,
		vehicleProductId: vehicles[activeVehicleIndex].productId,
		vehicleVariantId: vehicles[activeVehicleIndex].variantId,
		vehicleName: vehicles[activeVehicleIndex].name,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
}

export async function track_buyer_messaged_oem_support(
	oemProfileProps,
	checkoutCart,
	totalCartCost,
	buyerEmail,
	buyerMessage,
	supportTopicKey,
	buyerFullName,
	registrationState,
	isPreorderSession,
) {
	const vehicleNamesInCart = checkoutCart.vehicles
		? checkoutCart.vehicles.map((item) => item.name).join(', ')
		: 'none';
	const accessoryNamesInCart = checkoutCart.accessories
		? checkoutCart.accessories.map((item) => item.name).join(', ')
		: 'none';
	const contextForOem = `Buyer's registration state: ${registrationState}. Total cart cost: $${totalCartCost}. Vehicles in cart: ${vehicleNamesInCart}. Accessories in cart: ${accessoryNamesInCart},Checkout cart ID: ${
		checkoutCart.id || 'unknown'
	}.`;
	// not applying the block because we still want the message to be sent to an OEM
	// if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	const eventId = 'buyer_messaged_oem_support';

	const messageData = {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		oemName: oemProfileProps.name,
		oemEmail: oemProfileProps.emailForSupport,
		buyerEmail,
		buyerMessage,
		supportTopicKey,
		supportTopic: SUPPORT_TOPICS[supportTopicKey]?.title || 'Unknown',
		ekhoApp: EKHO_APPS_ENUM.CHECKOUT,
		// current page URL
		checkoutUrl: window.location.href,
		buyerCurrentUrl: window.location.href,
		contextForOem,
		buyerFullName,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
		toEmail: oemProfileProps.emailForSupport, // send email to OEM
		replyToEmail: buyerEmail, // let OEM reply to buyer
		from: null,
		identifier: buyerEmail, // associate it with the buyer email
	};

	try {
		const res = await sendTransactionalEmail(messageData, eventId);
		window.analytics.track('buyer_messaged_oem_support', messageData);
		return res;
	} catch (e) {
		createLog(
			'transactional-email-error',
			`Error sending buyer message to OEM support: ${e}`,
			'ERROR',
		);
		throw e;
	}
}

export function track_checkout_prequal_modal_opened(
	oemProfileProps,
	totalCartCost,
	isPreorderSession,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_prequal_modal_opened', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
}

export function page_checkout(totalCartCost, oemProfileProps) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.page('checkout', {
		totalCartCost,
		oemKey: oemProfileProps._oemKey,
		sourceData,
	});
}

export function page_preorder_checkout(totalCartCost, oemProfileProps) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.page('preorder_checkout', {
		totalCartCost,
		oemKey: oemProfileProps._oemKey,
		sourceData,
	});
}

export function page_preorder_checkout_success(oemProfileProps) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.page('preorder_checkout_success', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
	});
}

export function track_buyer_began_checkout(
	oemProfileProps,
	vehicles,
	numAccessories,
	totalCartCost,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('buyer_began_checkout', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		numVehicles: vehicles.length,
		numAccessories,
	});
}

export function track_buyer_began_preorder_checkout(
	oemProfileProps,
	vehicles,
	numAccessories,
	totalCartCost,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('buyer_began_preorder_checkout', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		numVehicles: vehicles.length,
		numAccessories,
	});
}

export function track_checkout_payment_type_toggled(
	oemProfileProps,
	totalCartCost,
	activePaymentToggle,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_payment_type_toggled', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		activePaymentToggle,
	});
}

export function track_checkout_buyer_did_press_continue(oemProfileProps, totalCartCost) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_buyer_did_press_continue', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
	});
}

export function convert_date_to_unix(date) {
	// Update segment
	const res = date.getTime() / 1000;
	return res;
}

export function identify_known_user(registeredUserProps, oemProfileProps) {
	// Update segment
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.identify(`${registeredUserProps.id}`, {
		sourceData,
		email: `${registeredUserProps.email}`,
		name: `${registeredUserProps.fullName}`,
		// If registeredUserProps has createdAt, convert it from a date to a unix timestamp. If it doesn't, set it to empty string
		created_at: registeredUserProps.createdAt
			? convert_date_to_unix(new Date(registeredUserProps.createdAt))
			: '',
		oemKey: `${oemProfileProps._oemKey}`,
	});
}

export function track_checkout_buyer_pressed_order(oemProfileProps, totalCartCost, checkoutCart) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_buyer_pressed_order', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		checkoutUrl: checkoutCart.ekhoCheckoutUrl
			? checkoutCart.ekhoCheckoutUrl
			: window.location.href,
	});
}

export function track_checkout_buyer_pressed_submit_credit_app(
	oemProfileProps,
	totalCartCost,
	checkoutCart,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_buyer_pressed_submit_credit_app', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		checkoutUrl: checkoutCart.ekhoCheckoutUrl
			? checkoutCart.ekhoCheckoutUrl
			: window.location.href,
	});
}

export function track_preorder_buyer_pressed_order(oemProfileProps, totalCartCost, checkoutCart) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('preorder_buyer_pressed_order', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		checkoutUrl: checkoutCart.ekhoCheckoutUrl
			? checkoutCart.ekhoCheckoutUrl
			: window.location.href,
	});
}

export function track_checkout_buyer_abandoned_cart(
	vehicles,
	uniqueAccessories,
	oemProfileProps,
	checkoutCart,
	abandonedCartId,
	isPreorderSession,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	// Create a productImgUrl variable. Set it to the image of the first vehicle in the cart. If there isn't a vehicle, set it to the image of the first accessory.
	let mainImgUrl = '';
	if (vehicles.length > 0) {
		mainImgUrl = vehicles.reduce((prev, current) =>
			prev.basePrice > current.basePrice ? prev : current,
		).imgUrl;
	} else if (uniqueAccessories?.length > 0) {
		mainImgUrl = uniqueAccessories.reduce((prev, current) =>
			prev.basePrice > current.basePrice ? prev : current,
		).imgUrl;
	}
	// Create a listOfItems string which is a comma seperated list of vehicle names and accessory names
	let listOfItems = '';
	vehicles.forEach((vehicle) => {
		listOfItems += `${vehicle.name}, `;
	});
	uniqueAccessories.forEach((accessory) => {
		const options = accessory.specs.map((spec) => spec.option).join(', ');
		listOfItems += `${accessory.name}${options ? ` - ${options}` : ''}, `;
	});

	window.analytics.track('checkout_buyer_abandoned_cart', {
		sourceData,
		oemKey: oemProfileProps?._oemKey,
		oemWebsite: oemProfileProps?.websiteUrl,
		oemName: oemProfileProps?.name,
		oemLogoUrl: oemProfileProps?.logos?.darkFull,
		oemBrandColor: oemProfileProps?.stylingMap.brandColor,
		oemBrandColorLight: oemProfileProps?.stylingMap.brandColorLight,
		productImgUrl: mainImgUrl,
		listOfItems,
		// Get the current URL
		checkoutUrl: checkoutCart.ekhoCheckoutUrl
			? checkoutCart.ekhoCheckoutUrl
			: window.location.href,
		abandonedCartId,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
	// Uncomment below to throw a warning when user tries to exit the page
	// event.preventDefault();
	// event.returnValue = 'Are you sure you want to abandon your cart?';
}

export function track_buyer_applied_discount(oem, code) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('buyer_applied_discount', {
		sourceData,
		oemKey: oem._oemKey,
		code,
	});
}

export function track_discount_failed(oem, code) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('discount_failed', {
		sourceData,
		oemKey: oem._oemKey,
		code,
	});
}

export function track_back_to_oem_cart(oemProfileProps) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('back_to_oem_cart', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
	});
}

export function track_contact_button_clicked(oemProfileProps, isPreorderSession = false) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('contact_button_clicked', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		ekhoApp: EKHO_APPS_ENUM.CHECKOUT,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
}

export function track_chat_button_clicked(
	oemProfileProps,
	isPreorderSession = false,
	supportTopicKey = 'Unknown',
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('chat_button_clicked', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		ekhoApp: EKHO_APPS_ENUM.CHECKOUT,
		supportTopicKey,
		supportTopic: SUPPORT_TOPICS[supportTopicKey]?.title || 'Unknown',
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
}

export function track_contact_info_filled(oemKey, isPreorderSession = false) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('contact_info_filled', {
		sourceData,
		oemKey,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
}

export function track_faq_link_clicked(oemKey) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('faq_link_clicked', {
		sourceData,
		oemKey,
	});
}

export function track_how_it_works_clicked(oemKey, isPreorderSession) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('how_it_works_clicked', {
		sourceData,
		oemKey,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
}

export function track_privacy_link_clicked(oemKey) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('privacy_link_clicked', {
		sourceData,
		oemKey,
	});
}

export function track_terms_link_clicked(oemKey) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('terms_link_clicked', {
		sourceData,
		oemKey,
	});
}

export function track_buyer_agreed_to_disclosures(oemProfileProps, isPreorderSession) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('buyer_agreed_to_disclosures', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
}

export function track_delivery_option_selected(oemKey, deliveryOption, isDefault) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('delivery_option_selected', {
		sourceData,
		oemKey,
		deliveryOption,
		isDefault,
	});
}

export function track_payment_option_selected(
	oemKey,
	paymentOption,
	isDefault,
	isPreorderSession = false,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('payment_option_selected', {
		sourceData,
		oemKey,
		paymentOption,
		isDefault,
		sessionType: isPreorderSession ? 'preorder' : 'checkout',
	});
}

export function track_tar_option_selected(oemKey, tarOption, isDefault) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('tar_option_selected', {
		sourceData,
		oemKey,
		tarOption,
		isDefault,
	});
}

export function track_order_placed_failed(oemProfileProps, totalCartCost, checkoutCart, reason) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('order_placed_failed', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		reason,
		checkoutUrl: checkoutCart.ekhoCheckoutUrl
			? checkoutCart.ekhoCheckoutUrl
			: window.location.href,
	});
}

export function track_submit_credit_app_failed(
	oemProfileProps,
	totalCartCost,
	checkoutCart,
	reason,
) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('submit_credit_app_failed', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		reason,
		checkoutUrl: checkoutCart.ekhoCheckoutUrl
			? checkoutCart.ekhoCheckoutUrl
			: window.location.href,
	});
}

export function track_preorder_placed_failed(oemProfileProps, totalCartCost, checkoutCart, reason) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('preorder_placed_failed', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		reason,
		checkoutUrl: checkoutCart.ekhoCheckoutUrl
			? checkoutCart.ekhoCheckoutUrl
			: window.location.href,
	});
}

export function track_moving_to_credit_app(oemProfileProps, totalCartCost, checkoutCart) {
	if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	window.analytics.track('moving_to_credit_app', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		checkoutUrl: checkoutCart.ekhoCheckoutUrl
			? checkoutCart.ekhoCheckoutUrl
			: window.location.href,
	});
}
