import React from 'react';
import { CardBody } from '@ekhodealer/ekho-common/components';
import PurchasePreviewContainer from '../purchasePreviewComponents/PurchasePreviewContainer';
import StackedContext from '../../contexts/stackedContext';

const PurchasePreviewLoading = () => {
	const { stacked } = React.useContext(StackedContext);
	return (
		<PurchasePreviewContainer>
			<CardBody
				className='p-0'
				style={{
					height: stacked
						? 'calc((var(--purchase-preview-percent-height) - 55px) * 0.3)'
						: '30vh',
					width: '100%',
				}}>
				<h1 className='card-title ekho-placeholder-wave h1'>
					<span className='ekho-placeholder col-6' />
				</h1>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center', // Center horizontally
						justifyContent: 'center', // Center vertically
						height: '90%',
						width: '100%', // Ensure it takes the full width
					}}
					className='ekho-placeholder-wave'>
					<div
						className='p-0 ekho-placeholder-wave'
						style={{ height: '40%', width: '100%' }}
						id='not-showing'>
						<span className='ekho-placeholder col-12 h-100' />
					</div>
					<br />
					<br />
					<div
						className='p-0 col-12 ekho-placeholder-wave'
						style={{
							height: '20%',
							padding: '0',
							alignItems: 'center',
							justifyContent: 'space-between',
							display: 'flex',
						}}>
						<div
							className='p-0 ekho-placeholder-wave'
							style={{
								height: '100%',
								width: '30%',
							}}>
							<span className='ekho-placeholder col-12 h-100' />
						</div>
						<div
							className='p-0 ekho-placeholder-wave'
							style={{
								height: '100%',
								width: '30%',
							}}>
							<span className='ekho-placeholder col-12 h-100' />
						</div>
						<div
							className='p-0 ekho-placeholder-wave'
							style={{
								height: '100%',
								width: '30%',
							}}>
							<span className='ekho-placeholder col-12 h-100' />
						</div>
					</div>
				</div>
			</CardBody>
		</PurchasePreviewContainer>
	);
};

export default PurchasePreviewLoading;
