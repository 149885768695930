import { createContext } from 'react';

const AccountDetailsContext = createContext({
	accountDetails: {
		emailAddress: null,
		fullName: null,
		phoneNumber: null,
	},
	setAccountDetails: () => {},
});

export default AccountDetailsContext;
