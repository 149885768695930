import React from 'react';
import PurchasePreviewLoading from './PurchasePreviewLoading';
import CheckoutInfoLoading from './CheckoutInfoLoading';

const WebLoading = () => {
	return (
		<>
			<div
				className='col-6 p-0'
				style={{
					height: '100vh',
					position: 'relative',
				}}>
				<PurchasePreviewLoading />
			</div>
			<div
				className='col-6 p-0'
				style={{
					height: '100vh',
				}}>
				<CheckoutInfoLoading />
			</div>
		</>
	);
};

export default WebLoading;
