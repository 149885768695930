import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ekhodealer/ekho-common/components';

const AccordionInnerContent = ({ iconName, titleString, listComponents, disclaimerComponent }) => {
	return (
		<>
			<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
				{iconName && (
					<Icon
						icon={iconName}
						color='info'
						styles={{
							marginRight: '0.5rem',
							width: '1.5rem',
							height: '1.5rem',
						}}
					/>
				)}
				<strong style={{ margin: 0 }}>{titleString}</strong>
			</div>
			<br />
			<ul className='mb-0'>{listComponents}</ul>
			<br />
			{disclaimerComponent}
		</>
	);
};

AccordionInnerContent.propTypes = {
	iconName: PropTypes.string,
	titleString: PropTypes.string.isRequired,
	listComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
	disclaimerComponent: PropTypes.node.isRequired,
};

AccordionInnerContent.defaultProps = {
	iconName: '',
};

export default AccordionInnerContent;
