// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { PageWrapper, Page } from '@ekhodealer/ekho-common/components';
import { layoutMenu } from '../../menu';

const CheckoutContainer = ({ children }) => {
	return (
		<PageWrapper title={layoutMenu.checkout.text}>
			<Page
				container='fluid'
				style={{ paddingRight: 0, paddingLeft: 0, paddingTop: 0, overflow: 'hidden' }}>
				<div className='row h-100' style={{ marginRight: 0, marginLeft: 0 }}>
					{children}
				</div>
			</Page>
		</PageWrapper>
	);
};

CheckoutContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CheckoutContainer;
