import React, { Suspense, useContext } from 'react';
import CheckoutContainer from '../../components/checkout/CheckoutContainer';
import MobileLoading from '../../components/loading/MobileLoading';
import WebLoading from '../../components/loading/WebLoading';
import ContentRoutes from './ContentRoutes';
import StackedContext from '../../contexts/stackedContext';

const mobileLoading = (
	<CheckoutContainer>
		<MobileLoading />
	</CheckoutContainer>
);

const webLoading = (
	<CheckoutContainer>
		<WebLoading />
	</CheckoutContainer>
);

const Content = () => {
	const { stacked } = useContext(StackedContext);
	return (
		<main className='content'>
			<Suspense fallback={stacked ? mobileLoading : webLoading}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
