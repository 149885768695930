import { createContext } from 'react';

const RegistrationAddressContext = createContext({
	registrationAddress: {
		addressString: null,
		addressLineOne: null,
		addressLineTwo: null,
		city: null,
		county: null,
		state: null,
		zip: null,
	},
	setRegistrationAddress: () => {},
});

export default RegistrationAddressContext;
