// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@ekhodealer/ekho-common/components';
import StackedContext from '../../contexts/stackedContext';
import { CHECKOUT_INFO_MAX_WIDTH, SCROLL_CONTAINER_ID } from '../../common/data/constants';

const CheckoutInfoContainer = ({ children }) => {
	const { stacked } = useContext(StackedContext);
	return (
		<Card
			// In stacked case, don't show any shadow

			shadow={stacked ? 'none' : ''}
			style={{
				height: stacked ? '' : '100%',
				borderRadius: 0,
				marginBottom: 0,
				padding: 0,
				boxShadow: '-10px 0 20px -5px rgba(0, 0, 0, 0.08)',
			}}>
			<CardBody
				className={stacked ? '' : SCROLL_CONTAINER_ID}
				style={{
					scrollBehavior: stacked ? 'auto' : 'smooth', // Adjusts scroll behavior based on stacked
					maxHeight: stacked ? 'none' : '100%', // Removes max height restriction in stacked
					overflowY: stacked ? 'visible' : 'auto', // Prevents scrolling in stacked by changing overflow
					paddingLeft: stacked ? '2rem' : '0',
					paddingRight: stacked ? '2rem' : '0',
					paddingTop: stacked ? '0.5rem' : '3rem',
					paddingBottom: stacked ? '0.5rem' : '3rem',
				}}>
				{!stacked ? (
					<div
						style={{
							maxWidth: `${CHECKOUT_INFO_MAX_WIDTH}px`,
							paddingLeft: '5rem',
							paddingRight: '2rem',
						}}>
						{children}
					</div>
				) : (
					children
				)}
			</CardBody>
		</Card>
	);
};

CheckoutInfoContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CheckoutInfoContainer;
